/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ColorRange } from "./HeatmapCharts";
import { TableMixByPartySize, TableCoverLineChar } from "./TableMixByPartySize";
import { CardTableExample } from "./GridTablesData";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_TABLE_SEAT_OPTIMIZATION } from "../../helpers/data_helper";
import Loader from "../../Components/Common/Loader";
import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { getDateByName, coverColor } from "../../helpers/api_helper";
import { RevenueTable } from "./RevenueTable";


const TableSeatOptimization = () => {  
  document.title = "Table Seat Optimization | " + process.env.REACT_APP_NAME;

  const [currency, setCurrency] = useState('$');
  const [customDate, setCustomDate] = useState([]);
  const [activeDate, setActiveDate] = useState({
    label: "Yesterday",
    value: "Yesterday",
  });
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const [mealPeriod, setMealPeriod] = useState(0);

  const [occupancyHeatmapData, setOccupancyHeatmapData] = useState({
    table: [],
    seat: [],
    revPASH: [],
  });
  const [occupancyHeatmapAjax, setOccupancyHeatmapAjax] = useState(true);
  // const [tableRevenueData, setTableRevenueData] = useState([]);
  // const [tableRevenueAjax, setTableRevenueAjax] = useState(true);
  const [tableMixPartyData, setTableMixPartyData] = useState({
    categories: [],
    series: [],
  });
  const [tableMixPartyAjax, setTableMixPartyAjax] = useState(true);
  const [tableMixPartyAvgData, setTableMixPartyAvgData] = useState({
    categories: [],
    series: [],
  });
  const [tableMixPartyAvgAjax, setTableMixPartyAvgAjax] = useState(true);
  const [selectedPageRT, setSelectedPageRT] = useState(0);
  const [datacolorArray, setdatacolorArray] = useState([]);
  const [allHaveCoverZero, setallHaveCoverZero] = useState(true);
  const [allHaveCoverZeroAvg, setallHaveCoverZeroAvg] = useState(true);

  const { filterData, filterAjax,currentOutlet } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
    currentOutlet: state.UserOutletGroup.currentOutlet
  }));

  useEffect(()=>{
    if(currentOutlet?.currency!=""){
      setCurrency(currentOutlet?.currency)
    }
  },[currentOutlet])

  useEffect(() => {
    localStorage.setItem('Table_Seat_optimization_SortType', '');
    if (filterData.mealperiod) {
      setFiltermealperiod(filterData.mealperiod);
    }
  }, [filterAjax, filterData]);

  useEffect(() => {

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      // setTableRevenueAjax(true);
      setOccupancyHeatmapAjax(true);
      setTableMixPartyAjax(true);
      var dateFilter =
        activeDate.value === "Custom"
          ? { from: customDate[0], to: customDate[1] }
          : getDateByName(activeDate.value);
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.occupancy_heatmap_op,
      }).then((response) => {
        setOccupancyHeatmapAjax(false);
        if (response.status === "ok") {
          setOccupancyHeatmapData(response.data);
        } else {
          console.log(response);
        }
      });

      // postDataAPI({dateFilter:dateFilter,mealPeriod:mealPeriod,serviceName:POST_TABLE_SEAT_OPTIMIZATION.serviceName,op:POST_TABLE_SEAT_OPTIMIZATION.table_revenue_op}).then(response => {
      //   setTableRevenueAjax(false)
      //   if(response.status==='ok'){
      //     setTableRevenueData(response.data);
      //   }else{
      //     console.log(response);
      //   }
      // });
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_mixparty_op,
      }).then((response) => {
        setTableMixPartyAjax(false);
        if (response.status === "ok") {
          setTableMixPartyData(response.data);              
          setallHaveCoverZero(response.data.series.every(obj => obj.name === "Cover - 0"));

          for(let i = 0; i < response.data.series.length; i++){
            // clearArray();
            datacolorArray.push(getRandomColor());
          }


        } else {
          console.log(response);
        }
      });
      setTableMixPartyAvgAjax(true)
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_cover_avgchk,
      }).then((response) => {
        setTableMixPartyAvgAjax(false);
        if (response.status === "ok") {
          setTableMixPartyAvgData(response.data);
          setallHaveCoverZeroAvg(response.data.series.every(obj => obj.name === "Cover - 0"))
        } else {
          console.log(response);
        }
      });
    }
  }, [activeDate, customDate, mealPeriod]);

  // console.log("datacolorArray", datacolorArray);  


  const clearArray = () => {
    setdatacolorArray([]);
  };

  // function getRandomColor() {
  //   // Generate a random number between 0 and 16777215 (decimal equivalent of FFFFFF in hexadecimal)
  //   var randomColor = Math.floor(Math.random() * 16777215).toString(16);
  
  //   // Pad the string with zeros to ensure it is always six digits long
  //   return '#' + '0'.repeat(6 - randomColor.length) + randomColor;
  // }

  function getRandomColor() {
    // Generate a random number between 0 and 7FFFFF (decimal equivalent of #7F7F7F in hexadecimal)
    var randomColor = Math.floor(Math.random() * 0x7FFFFF).toString(16);
  
    // Pad the string with zeros to ensure it is always six digits long
    return '#' + '0'.repeat(6 - randomColor.length) + randomColor;
  }      
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Table Seat Optimization" pageTitle="Dashboard" />
          <form className="mb-3">
            <Row className="mb-4 justify-content-sm-end">
              <Col className="mb-2 mb-lg-0" sm={12} lg={6}>
                <CustomDatePicker
                  activeDate={activeDate}
                  setActiveDate={setActiveDate}
                  customDate={customDate}
                  setCustomDate={setCustomDate}
                  posEnd={false}
                />
              </Col>
              <Col sm={12} lg={6}>
                <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => setMealPeriod(event.target.value)}
                >
                  <option value="0">All Meal Periods</option>
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.sp_id} value={item.sp_id}>
                          {item.title +
                            " (" +
                            item.start_time +
                            " To " +
                            item.end_time +
                            ")"}
                        </option>
                      );
                    })}
                </select>
              </Col>
            </Row>
          </form>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Col xl={4}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">Table Occupancy</h4>
                      </div>
                      <div className="card-body px-2">
                        {occupancyHeatmapAjax && <Loader />}
                        <div
                          className={occupancyHeatmapAjax ? "opacity-0" : ""}
                        >
                          <ColorRange
                            dataSeries={occupancyHeatmapData.table}
                            dataText="Average Occ"
                            dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={4}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">Seat Occupancy</h4>
                      </div>

                      <div className="card-body px-2">
                        {occupancyHeatmapAjax && <Loader />}
                        <div
                          className={occupancyHeatmapAjax ? "opacity-0" : ""}
                        >
                          <ColorRange
                            dataSeries={occupancyHeatmapData.seat}
                            dataText="Average Occ"
                            dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xl={4}>
                    <Card>
                      <div className="card-header px-2">
                        <h4 className="card-title mb-0">RevPASH</h4>
                      </div>

                      <div className="card-body px-2">
                        {occupancyHeatmapAjax && <Loader />}
                        <div
                          className={occupancyHeatmapAjax ? "opacity-0" : ""}
                        >
                          <ColorRange
                            dataSeries={occupancyHeatmapData.revPASH}
                            dataText="RevPASH"
                            dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Table Mix By Party Size
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {tableMixPartyAjax && <Loader />}
                        <div className={tableMixPartyAjax ? "opacity-0" : ""}>
                          <TableMixByPartySize
                            data={tableMixPartyData}
                            dataColors={coverColor}
                            // dataColors='["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning"]'
                            allHaveCoverZero={allHaveCoverZero}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          AVG Check Spent By Slots
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {tableMixPartyAvgAjax && <Loader />}
                        <div className={tableMixPartyAvgAjax ? "opacity-0" : ""}>
                          <TableCoverLineChar
                            data={tableMixPartyAvgData}
                            dataColors={coverColor}
                            allHaveCoverZeroAvg={allHaveCoverZeroAvg}
                            // dataColors='["--vz-primary", "--vz-success", "--vz-danger", "--vz-warning"]'
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {/* <CardTableExample data={tableRevenueData} /> */}
                    <RevenueTable
                      activeDate={activeDate}
                      customDate={customDate}
                      mealPeriod={mealPeriod}
                      selectedPage={selectedPageRT}
                      setSelectedPage={setSelectedPageRT}
                      currency={currency}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TableSeatOptimization;
