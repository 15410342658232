import React, { useEffect, createRef, useState } from "react";
import classnames from "classnames";
import {
    Card, CardHeader, CardBody, Modal,
    ModalHeader,
    ModalBody, Col,
    Row, Dropdown, DropdownMenu, DropdownToggle, Button, DropdownItem, Input, Label, ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import CourseBadge from "../../Components/Common/CourseBadge"
import Loader from "../../Components/Common/Loader"
import { getDateByName, currencyFormatWithAlert, convertDateFormate, courseColor } from "../../helpers/api_helper"
import CustomPagination from "../../Components/Common/CustomPagination";
import PerformanceBadge from "../../Components/Common/PerformanceBadge";
const itemsPerPage = 50;

const ServerTopBottomPerformanceTable = ({ currency,activeDate, customDate, mealPeriod, server_id, selectedPageST, setSelectedPageST }) => {

    const [selectedPage, setSelectedPage] = useState(0);
    const [serverPerformanceAjax, setServerPerformanceAjax] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [serverPerformances, setServerPerformances] = useState([]);

    const [justifyTab, setjustifyTab] = useState("course");
    const justifyToggle = (tab) => {
        if (justifyTab !== tab) {
            setjustifyTab(tab);
        }
    };
    
    useEffect(() => {
        setServerPerformanceAjax(true);
        postDataAPI({
            dateFilter: activeDate.value === 'Custom' ? { from: convertDateFormate(customDate[0]), to: convertDateFormate(customDate[1]) } : getDateByName(activeDate.value),
            serviceName: POST_KPI_DASHBOARD.serviceName,
            op: POST_KPI_DASHBOARD.server_top_bottom_performace_op,
            server_id: server_id,
            mealPeriod: mealPeriod,
            page: selectedPage
        }).then((response) => {
            setServerPerformanceAjax(false);
            if (response.status === "ok") {
                setServerPerformances(response.data.data);
                setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
            } else {
                console.log(response);
                setPageCount(0);
            }
        });
    }, [activeDate, customDate, mealPeriod, server_id, selectedPage]);

    const [serverData, setServerData] = useState([]);
    const [serverModalArray, setserverModalArray] = useState({});
    const [serverModalAjax, setServerModalAjax] = useState(true);
    const [serverModalDetailAjax, setServerModalDetailAjax] = useState(true);
    const serverSeries = serverData.serverSeries;
    const [modalServerName, setmodalServerName] = useState('');
    const [modalServerID, setmodalServerID] = useState('');
    const [modalServerCheckAvg, setModalServerCheckAvg] = useState(0);
    const [openFlag, setopenFlag] = useState('');

    // useEffect(() => {
    //     if (activeDate.value !== 'Custom' || (activeDate.value === 'Custom' && customDate[0] && customDate[1])) {
    //         setServerModalAjax(true);
    //         postDataAPI({
    //             dateFilter: activeDate.value === 'Custom' ? { from: convertDateFormate(customDate[0]), to: convertDateFormate(customDate[1]) } : getDateByName(activeDate.value),
    //             serviceName: POST_KPI_DASHBOARD.serviceName,
    //             op: POST_KPI_DASHBOARD.server_course_performance_op,
    //             mealPeriod: mealPeriod,
    //             server_id: server_id,
    //             page: selectedPageST,
    //         }).then((response) => {
    //             setServerModalAjax(false);
    //             if (response.status === "ok") {
    //                 setServerData(response.data);
    //             } else {
    //                 console.log(response);
    //             }
    //         });
    //     }
    // }, [activeDate, customDate, mealPeriod, selectedPageST, server_id])

    const [modalServerModal, setModalServerModal] = useState(false);
    function togServerModal() {
        setModalServerModal(!modalServerModal);
    }

    const handleDataPointSelection = (course, performer, type) => {

        let post_server_id;
        let performerName;

        if (type === 'top') {
            const topServerIDs = course.topServerID.split(',');
            const topPerformers = course.top_performer.split(', ');

            // Finding index of performer clicked
            const index = topPerformers.indexOf(performer);
            post_server_id = topServerIDs[index];
            performerName = performer;

        } else {
            const bottomServerIDs = course.bottomServerID.split(',');
            const bottomPerformers = course.bottom_performer.split(', ');

            // Finding index of performer clicked
            const index = bottomPerformers.indexOf(performer);
            post_server_id = bottomServerIDs[index];
            performerName = performer;
        }

        setmodalServerName(performerName);
        setmodalServerID(post_server_id);

        if (activeDate.value !== 'Custom' || (activeDate.value === 'Custom' && customDate[0] && customDate[1])) {
            setModalServerModal(true);
            setServerModalDetailAjax(true);
            postDataAPI({
                dateFilter: activeDate.value === 'Custom' ? { from: convertDateFormate(customDate[0]), to: convertDateFormate(customDate[1]) } : getDateByName(activeDate.value),
                serviceName: 'server_data',
                op: 'server_course_items',
                mealPeriod: mealPeriod,
                server_id: post_server_id,
                page: selectedPageST,
            }).then((response) => {
                setServerModalDetailAjax(false);
                if (response.status === "ok") {
                    setserverModalArray(response.data.data);
                    setopenFlag(true);
                    setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
                    setModalServerCheckAvg(response.data.data.average_check);
                } else {
                    setModalServerModal(false);
                    setPageCount(0);
                    setopenFlag(false);
                }
            });
        }
    };

    /******** Show and Hide Columns *********/
    const [sortColumn_second, SetSortColumn_second] = useState('');
    const columnDef_second = [
        {
            name: 'Course',
            key: 'course_name',
            className: "text-start",
        },
        {
            name: 'Sold',
            key: 'sold'
        },
        {
            name: 'Avg Check',
            key: 'average_check'
        },
        {
            name: 'Course Rev of Overall',
            key: 'revenue_percetage'
        },
        {
            name: 'Top performance',
            key: 'top_performer',
        },
        {
            name: 'of revenue',
            key: 'percet_revenue_top'
        },
        {
            name: 'Bottom performance',
            key: 'bottom_performer'
        },
        {
            name: 'of revenue',
            key: 'percet_revenue_bottom',
        }
    ]
    const [showColumns_second, setShowColumns_second] = useState(columnDef_second.map((column) => column.key));
    const [isOpen_second, setIsOpen_second] = useState(false);
    const columnDropdownRef_second = createRef();
    const toggleDropdown_second = (e) => {
        if (columnDropdownRef_second.current.contains(e.target)) return;
        setIsOpen_second(!isOpen_second);
    };
    const handleColumnHideShow_second = (event, columnName) => {
        event.stopPropagation();
        if (showColumns_second.includes(columnName)) {
            setShowColumns_second(showColumns_second.filter((column) => column != columnName))
        } else {
            setShowColumns_second([...showColumns_second, columnName]);
        }
        console.log(event.target.value)
    }
    /****************************************/

    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Server Top/Bottom performance</h4>

                <Dropdown isOpen={isOpen_second} toggle={toggleDropdown_second} className="ms-3" style={{ borderRadius: '0.25rem' }}>
                    <ButtonGroup>
                        <Button color="info">Columns</Button>
                        <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                    </ButtonGroup>
                    <DropdownMenu className="dropdown-menu-end" end={true}>
                        <div ref={columnDropdownRef_second}>
                            {columnDef_second.map((column) => (
                                <DropdownItem onClick={(event) => { handleColumnHideShow_second(event, column.key) }} key={column.key}>
                                    <div className="form-check mb-1">
                                        <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns_second.includes(column.key)} value={column.key} />
                                        <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                                    </div>
                                </DropdownItem>
                            ))}
                        </div>
                    </DropdownMenu>
                </Dropdown>


            </CardHeader>

            <CardBody>
                {
                    serverPerformanceAjax && <Loader />
                }
                {!serverPerformanceAjax && <>
                    <div className={
                        serverPerformanceAjax
                            ? "opacity-0 table-responsive table-card"
                            : "table-responsive table-card"
                    }>
                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                                <tr style={{ textAlign: 'center' }}>
                                    {columnDef_second.map((column) => (
                                        showColumns_second.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.name}</th>
                                    ))}
                                    {/* <th scope="col">Course</th>
                            <th scope="col">Sold</th>
                            <th scope="col">Avg Check</th>
                            <th scope="col">Course Rev of Overall (%)</th>
                            <th scope="col">Top performance</th>
                            <th scope="col">% of revenue</th>
                            <th scope="col">Bottom performance</th>
                            <th scope="col">% of revenue</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    serverPerformances.map((course, key) => {
                                        return <tr key={key} style={{ textAlign: 'center' }}>
                                            {showColumns_second.includes('course_name') && <td className="text-start">
                                                <CourseBadge course_name={course.course_name} />
                                            </td>
                                            }

                                            {showColumns_second.includes('sold') &&
                                                <td >
                                                    {(course.sold < 0) ? <span className={course.sold < 0 ? "text-danger" : ""}>({course.sold})</span> : <span className="">{course.sold}</span>}
                                                </td>
                                            }

                                            {showColumns_second.includes('average_check') && <td>
                                                {currencyFormatWithAlert(course.average_check,currency)}
                                            </td>
                                            }

                                            {showColumns_second.includes('revenue_percetage') && <td>
                                                {(course.revenue_percetage < 0) ? <span className={course.revenue_percetage < 0 ? "text-danger" : ""}>({-course.revenue_percetage}%)</span> : <span className="">{course.revenue_percetage}%</span>}
                                            </td>
                                            }

                                            {showColumns_second.includes('top_performer') && <td>
                                                {course.top_performer.split(', ').map((performer, index) => (
                                                    <span key={index}>
                                                        <span onClick={() => handleDataPointSelection(course, performer, 'top')} style={{ cursor: 'pointer', color: '#405189' }}>{performer}</span>
                                                        {index < course.top_performer.split(', ').length - 1 && ", "}
                                                    </span>
                                                ))}
                                            </td>
                                            }

                                            {showColumns_second.includes('percet_revenue_top') && <td>
                                                {(course.percet_revenue_top < 0) ? <span className={course.percet_revenue_top < 0 ? "text-danger" : ""}>({-course.percet_revenue_top}%)</span> : <span className="">{course.percet_revenue_top}%</span>}
                                            </td>
                                            }

                                            {showColumns_second.includes('bottom_performer') && <td>
                                                {course.bottom_performer.split(', ').map((performer, index) => (
                                                    <span key={index}>
                                                        <span onClick={() => handleDataPointSelection(course, performer, 'bottom')} style={{ cursor: 'pointer', color: '#405189' }}>{performer}</span>
                                                        {index < course.bottom_performer.split(', ').length - 1 && ", "}
                                                    </span>
                                                ))}
                                            </td>
                                            }

                                            {showColumns_second.includes('percet_revenue_bottom') && <td>
                                                {(course.percet_revenue_bottom < 0) ? <span className={course.percet_revenue_bottom < 0 ? "text-danger" : ""}>({-course.percet_revenue_bottom}%)</span> : <span className="">{course.percet_revenue_bottom}%</span>}
                                            </td>
                                            }

                                            {/* <td><CourseBadge course_name={course.course_name} /></td>
                                    <td>{(course.sold < 0) ? <span className={course.sold < 0 ? "text-danger" : ""}>({course.sold})</span> : <span className="">{course.sold}</span>}
                                    </td>
                                    <td>{currencyFormatWithAlert(course.average_check,currency)}</td>
                                    <td>{(course.revenue_percetage < 0) ? <span className={course.revenue_percetage < 0 ? "text-danger" : ""}>({-course.revenue_percetage}%)</span> : <span className="">{course.revenue_percetage}%</span>}</td>
                                    <td>{course.top_performer.split(', ').map((performer, index) => (
                                        <span key={index}>
                                            <span onClick={() => handleDataPointSelection(course, performer,'top')} style={{ cursor: 'pointer', color: '#405189' }}>{performer}</span>
                                            {index < course.top_performer.split(', ').length - 1 && ", "}
                                        </span>
                                    ))}</td>
                                    <td>{(course.percet_revenue_top < 0) ? <span className={course.percet_revenue_top < 0 ? "text-danger" : ""}>({-course.percet_revenue_top}%)</span> : <span className="">{course.percet_revenue_top}%</span>}</td>
                                    <td>{course.bottom_performer.split(', ').map((performer, index) => (
                                        <span key={index}>
                                            <span onClick={() => handleDataPointSelection(course, performer, 'bottom')} style={{ cursor: 'pointer', color: '#405189' }}>{performer}</span>
                                            {index < course.bottom_performer.split(', ').length - 1 && ", "}
                                        </span>
                                    ))}</td>
                                    <td>{(course.percet_revenue_bottom < 0) ? <span className={course.percet_revenue_bottom < 0 ? "text-danger" : ""}>({-course.percet_revenue_bottom}%)</span> : <span className="">{course.percet_revenue_bottom}%</span>}</td> */}
                                        </tr>;
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 float-end">
                        <nav>
                            <CustomPagination
                                pageCount={pageCount}
                                setSelectedPage={setSelectedPage}
                            />
                        </nav>
                    </div>
                </>
                }
            </CardBody>


            {/* Modal for onclick Server Chart */}
            <Modal className="modal-dialog modal-lg custom-bg" isOpen={modalServerModal} toggle={() => { togServerModal(); }} id="fullscreeexampleModal">
                <ModalHeader className="modal-title" id="exampleModalFullscreenLabel" toggle={() => { togServerModal(); }}>
                </ModalHeader>
                <ModalBody>

                    <Card className="mb-1">

                        <CardBody style={{ backgroundColor: '#405189', color: '#fff', borderRadius: '8px 8px 0px 0px' }}>
                            <Row>
                                <Col lg={4}>
                                    Server Name :
                                    <span style={{ background: '#fff', color: '#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px' }}>
                                        {modalServerName}
                                    </span>
                                </Col>
                                {!serverModalDetailAjax && <><Col lg={2}>
                                    Cover :
                                    <span style={{ background: '#fff', color: '#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px' }}>
                                        {serverModalArray.cover_percentage}%
                                    </span>
                                </Col>
                                    <Col lg={3}>
                                        Revenue :
                                        <span style={{ background: '#fff', color: '#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px' }}>
                                            {serverModalArray.revenue_percetage}%
                                        </span>
                                    </Col>
                                    <Col lg={3}>
                                        Avg Check :
                                        <span style={{ background: '#fff', color: '#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px' }}>
                                            {currencyFormatWithAlert(modalServerCheckAvg,currency)}
                                        </span>
                                    </Col></>
                                }
                            </Row>
                        </CardBody>
                    </Card>

                    <Nav pills className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", background:"transparent" }} className={classnames({ active: justifyTab === "course", })} onClick={() => { justifyToggle("course"); }} >
                                Course
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyTab === "item", })} onClick={() => { justifyToggle("item"); }} >
                                Item
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={justifyTab} className="text-muted">

                        <TabPane tabId="course" id="course">
                            <Card style={{borderRadius: '0px 0px 8px 8px'}}>                                                                                       
                                <CardBody>                                                        
                                {
                                    serverModalDetailAjax && <Loader />
                                }
                                {!serverModalDetailAjax && <>
                                    <div
                                        className={
                                            serverModalDetailAjax
                                                ? "opacity-0 table-responsive table-card"
                                                : "table-responsive table-card"
                                        }
                                    >
                                        <table className="table table-borderless table-centered align-middle table-nowrap mb-3 text-center">
                                            <thead className="text-muted table-light">
                                                <tr>
                                                    <th scope="col">Course</th>
                                                    <th scope="col">Sold</th>
                                                    <th scope="col">Avg Check</th>
                                                    <th scope="col">Vs Team Check</th>
                                                    <th scope="col">Course Rev of Overall (%)</th>
                                                    <th scope="col">Performance</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(serverModalArray.courses || []).map((course, key) => (
                                                    <tr key={key}>
                                                        <td><CourseBadge course_name={course.course_name} /></td>
                                                        <td>{course.sold}</td>
                                                        <td>{currencyFormatWithAlert(course.average_check,currency)}</td>
                                                        <td>{currencyFormatWithAlert(course.team_check,currency)}</td>
                                                        <td>{(course.revenue_percetage < 0) ? <span className={course.revenue_percetage < 0 ? "text-danger" : ""}>({-course.revenue_percetage}%)</span> : <span className="">{course.revenue_percetage}%</span>}</td>
                                                        <td>{<PerformanceBadge title={course.performance} />}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                }
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="item" id="item">
                            <Card style={{borderRadius: '0px 0px 8px 8px'}}>                                                                                       
                                <CardBody>
                                    {
                                        serverModalDetailAjax && <Loader />
                                    }
                                    {!serverModalDetailAjax && <>
                                        <div
                                            className={
                                                serverModalDetailAjax
                                                    ? "opacity-0 table-responsive table-card"
                                                    : "table-responsive table-card"
                                            }
                                            style={{ maxHeight: '500px', overflow: 'auto'}}
                                        >
                                            <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                                                <thead className="text-muted table-light">
                                                    <tr style={{ position: 'sticky', top: '-2px', zIndex: 100 }}>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">% of Total Sold</th>
                                                        <th scope="col">Performance Group</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(serverModalArray.items || []).map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{item.item_name}</td>
                                                            <td><CourseBadge course_name={item.category} /></td>
                                                            <td>{item.chosen_percetage}%</td>
                                                            <td>
                                                                <span
                                                                    className={`badge bg-${item.performance_group === "Plowhorse"
                                                                            ? "warning"
                                                                            : item.performance_group === "Dog"
                                                                                ? "danger"
                                                                                : item.performance_group === "Cash Cow"
                                                                                    ? "warning"
                                                                                    : item.performance_group === "Puzzle"
                                                                                        ? "light text-dark"
                                                                                        : "success"
                                                                        }
                                                        `}
                                                                >
                                                                    {item.performance_group}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        </>
                                        }

                                </CardBody>
                            </Card>                  
                        </TabPane>
                    </TabContent>
                   
                </ModalBody>
            </Modal>
        </Card>
    )
}

export default ServerTopBottomPerformanceTable;