/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState,useMemo } from "react";
import { useSelector } from 'react-redux';

import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
  Input,
  Label
} from "reactstrap";
import { currencyFormatWithoutDecimal, currencyFormatWithAlert } from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import itemCategory from "../../assets/images/item-category.jpg";
import Loader from "../../Components/Common/Loader";
import { getDateByName, convertDateFormate } from "../../helpers/api_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import RecommendationFeedback from "./RecommendationFeedback";
import VisualModal from "./VisualModal";
import SellingItemPopup from "./SellingItemPopup";
const columnDef = [
  {
    name: 'Item',
    key: 'name',
    sort: true,
    className: "text-start",
  },
  {
    name: 'Course',
    key: 'course_name'
  },
  {
    name: 'Sold',
    key: 'sold',
    sort: true,
  },
  {
    name: 'Menu Price',
    key: 'sell_price',
    sort: true,
  },
  {
    name: 'Actualised Price',
    key: 'actualised_price',
  },
  {
    name: 'Item Cost',
    key: 'item_cost',
    sort: true,
  },
  {
    name: 'Item Gross Profit',
    key: 'item_gross_profit'
  },
  {
    name: 'Total Cost',
    key: 'total_cost',
  },
  {
    name: 'Total Sales',
    key: 'total_sales',
    sort: true,
  },
  {
    name: 'Sales Mix',
    key: 'sales_mix',
  },
  {
    name: 'Revenue Mix',
    key: 'revenueMix',
  },
  {
    name: 'Contribution Margin',
    key: 'ContributionMargin',
    sort: true,
  },
  {
    name: 'Profit Contribution',
    key: 'profit_contribution',
  },
  {
    name: 'Popularity Contribution',
    key: 'popularity_contribution',
  },
  {
    name: 'Performance Group',
    key: 'performance_group',
  }
];
const itemsPerPage = 50;
const BestSellingProducts = ({
  globalDate,
  rightFilter,
  globalCustomDate,
  mealPeriod,
  defaultCourse,
  recalculate,
  activeComparisonData,
  comparisonDate,
  currency,
  item
}) => {
  const [selectedPage, setSelectedPage] = useState(0);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [sellingItemAjax, setSellingItemAjax] = useState(true);
  const [sellingItem, setSellingItem] = useState([]);
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const [showVisualModal, setShowVisualModal] = useState(false);
  /******** Sorting **** Starts*****/
  const [sortColumn, setSortColumn] = useState('');
  const [sortColumnType, setSortColumnType] = useState('ASC');
  const [limitdroddown, setlimitdroddown] = useState('50');
  
  const [showItemModal, setshowItemModal] = useState(false);
  const [itemData, setItemData] = useState({});
  const [showColumns, setShowColumns] = useState(columnDef.map((column) => column.key));
  const [isOpen, setIsOpen] = useState(false);


  
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  
  const handleSort = async () => {
    setSellingItemAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      const response=await postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.selling_item_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        sort_column: sortColumn,
        sort_type: sortColumnType,
        limit: limitdroddown
      })

      setSellingItemAjax(false);
      if (response.status === "ok") {
        setSellingItem(response.data.data);
        setPageCount(Math.ceil(response.data.totalData / limitdroddown));
      } else {
        console.log(response);
        setPageCount(0);
      }
    }else{
      setSellingItemAjax(false);
    }
  };
  /************END Sorting**************/

  useEffect(() => {
    console.log("use Effect Call",new Date());
    handleSort();    
  }, [
    globalDate,
    globalCustomDate,
    mealPeriod,
    defaultCourse,
    selectedPage,
    recalculate,
    sortColumn,
    sortColumnType
  ]);

  /*********** Item Name Modal ********/  
  function togItemName() {
    setshowItemModal(!showItemModal);
  }
  const handleItemName = (item) => {
    setshowItemModal(true);
    setItemData(item);
  }
  /*********** END **********/

  const handleLimit = (e) => {
    console.log(e.target.value);

    setlimitdroddown(e.target.value);

    if (e.target.value !== '') {

      setSellingItemAjax(true);
      let leftFilterData = false;
      if (
        globalDate.value !== "Custom" ||
        (globalDate.value === "Custom" &&
          globalCustomDate[0] &&
          globalCustomDate[1])
      ) {
        leftFilterData =
          globalDate.value === "Custom"
            ? {
              from: convertDateFormate(globalCustomDate[0]),
              to: convertDateFormate(globalCustomDate[1]),
            }
            : getDateByName(globalDate.value);
      }
      if (leftFilterData) {
        postDataAPI({
          dateFilter: leftFilterData,
          serviceName: POST_MENU_OPTIMIZATION.serviceName,
          op: POST_MENU_OPTIMIZATION.selling_item_op,
          mealPeriod: mealPeriod,
          course: defaultCourse,
          page: 0,
          recalculate: recalculate,
          sort_column: '',
          sort_type: '',
          limit: e.target.value
        }).then((response) => {
          setSellingItemAjax(false);
          if (response.status === "ok") {
            setSellingItem(response.data.data);
            setPageCount(Math.ceil(response.data.totalData / e.target.value));
          } else {
            console.log(response);
            setPageCount(0);
          }
        });
      }
    }

  }

  const handleExport = (e) => {

    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.selling_item_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        sort_column: '',
        sort_type: '',
        limit: limitdroddown,
        export: 1,
        exportColumns: showColumns
      }).then((response) => {
        setSellingItemAjax(false);
        if (response.status === "ok") {
          // console.log(response.data.data.filename);
          window.open(response.data.data.filename);
        } else {
          console.log(response);

        }
      });
    }

  };

  /******** Show and Hide Columns *********/
  const columnDropdownRef = createRef();
  const toggleDropdown = (e) => {
    if (columnDropdownRef.current.contains(e.target)) return;

    setIsOpen(!isOpen);
  };
  const handleColumnHideShow = (event, columnName) => {
    event.stopPropagation();
    if (showColumns.includes(columnName)) {
      setShowColumns(showColumns.filter((column) => column != columnName))
    } else {
      setShowColumns([...showColumns, columnName]);
    }
    console.log(event.target.value)
  }
  
  /****************************************/
  const handleColumnSort=(column)=>{
    setSortColumnType(sortColumn === column.key && sortColumnType === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(column.key);
  }
  const filteredColumns = useMemo(() => columnDef.filter(column => showColumns.includes(column.key)), [showColumns, columnDef]);

  
  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Selling Items</h4>
          <div className="flex-shrink-0">
            <div className="d-flex">

              <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{ borderRadius: '0.25rem', marginRight: '15px' }}>
                <ButtonGroup>
                  <Button color="info">Columns</Button>
                  <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown-menu-end" end={true}>
                  <div ref={columnDropdownRef}>
                    {columnDef.map((column) => (
                      <DropdownItem onClick={(event) => { handleColumnHideShow(event, column.key) }} key={column.key}>
                        <div className="form-check mb-1">
                          <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key} />
                          <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                        </div>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>
              </Dropdown>

              <span className="p-2">Show</span>
              <select defaultValue="50" className="form-select" id="selling_item_limit" style={{ cursor: 'pointer' }} onChange={handleLimit}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
              <span className="p-2">entries</span>
              {/*<Button
                className="ms-3"
                color="primary"
                onClick={togCollaboration}
              >
                Correlation
              </Button> */}
              <Button
                className="ms-3"
                color="primary"
                onClick={() => setShowVisualModal(true)}
              >
                Visual
              </Button>
              <Button
                className="ms-3"
                color="primary"
                onClick={() => setShowRecommendModal(true)}
              >
                Recommendation
              </Button>
              {/* <ExportExcel fileName="SellingItems" excelData={sellingItem} /> */}
              <Button
                className="ms-3"
                color="primary"
                onClick={handleExport}
              >
                Export
              </Button>

            </div>
          </div>
        </CardHeader>
        <CardBody>
          {sellingItemAjax && <Loader />}
          <div className={sellingItemAjax ? "opacity-0" : ""}>
            {sellingItemAjax && <Loader />}
            <div
              className={
                sellingItemAjax
                  ? "opacity-0 table-responsive table-card"
                  : "table-responsive table-card"
              }
              style={{ maxHeight: '500px', overflow: 'auto' }}
            >
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                <thead className="text-muted table-light">
                  <tr style={{ position: 'sticky', top: '-2px', zIndex: 100 }}>
                    {filteredColumns.map((column) => (
                      // eslint-disable-next-line no-undef
                      <th className={column.className} key={column.key} scope="col">{column.name} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn == column.key && sortColumnType == 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={()=>handleColumnSort(column)}></i>}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(sellingItem || []).map((item, key) => (
                    <tr key={key}>

                      {showColumns.includes('name') &&
                        <td className="text-start" onClick={() => handleItemName(item)} style={{ cursor: 'pointer' }}>
                          {item.name}
                          <i className="ri-external-link-line" style={{ fontSize: '18px', marginLeft: '10px', position: 'relative', top: '5px', color: '#405189' }}></i>
                        </td>
                      }
                      {showColumns.includes('course_name') &&
                        <td>
                          <span
                            className={`badge bg-${item.course_name === "Starter"
                              ? "warning"
                              : item.course_name === "Drinks"
                                ? "danger"
                                : item.course_name === "Main"
                                  ? "primary"
                                  : item.course_name === "Desserts"
                                    ? "success"
                                    : item.course_name === "Others"
                                      ? "info"
                                      : "light text-dark"
                              }
                                        `}
                          >
                            {item.course_name}
                          </span>
                        </td>
                      }

                      {showColumns.includes('sold') && <td>{item.sold}</td>}
                      {showColumns.includes('sell_price') && <td>{currencyFormatWithAlert(item.sell_price,currency)}</td>}
                      {showColumns.includes('actualised_price') && <td>{currencyFormatWithAlert(item.actualised_price,currency)}</td>}
                      {showColumns.includes('item_cost') && <td>{currencyFormatWithAlert(item.item_cost,currency)}</td>}
                      {showColumns.includes('item_gross_profit') && <td>{currencyFormatWithAlert(item.item_gross_profit,currency)}</td>}
                      {showColumns.includes('total_cost') && <td>{currencyFormatWithAlert(item.total_cost,currency)}</td>}
                      {showColumns.includes('total_sales') && <td>{currencyFormatWithAlert(item.total_sales,currency)}</td>}
                      {showColumns.includes('sales_mix') && <td>{parseFloat(item.sales_mix).toFixed(2) + "%"}</td>}
                      {showColumns.includes('revenueMix') && <td>{parseFloat(item.revenueMix).toFixed(2) + "%"}</td>}
                      {showColumns.includes('ContributionMargin') && <td>{currencyFormatWithAlert(item.ContributionMargin,currency)}</td>}
                      {showColumns.includes('profit_contribution') &&
                        <td>
                          <span
                            className={`badge badge-soft-${item.profit_contribution === "Low"
                              ? "danger"
                              : "success"
                              }
                            `}
                          >
                            {item.profit_contribution}
                          </span>
                        </td>
                      }
                      {showColumns.includes('popularity_contribution') &&
                        <td>
                          <span
                            className={`badge badge-soft-${item.popularity_contribution === "Low"
                              ? "danger"
                              : "success"
                              }
                            `}
                          >
                            {item.popularity_contribution}
                          </span>
                        </td>
                      }
                      {showColumns.includes('performance_group') &&
                        <td>
                          <span
                            onClick={ShowModelPopupToggle}
                            className={`badge bg-${item.performance_group === "Plowhorse"
                              ? "warning"
                              : item.performance_group === "Dog"
                                ? "danger"
                                : item.performance_group === "Cash Cow"
                                  ? "warning"
                                  : item.performance_group === "Puzzle"
                                    ? "light text-dark"
                                    : "success"
                              }
                            `}
                          >
                            {item.performance_group}
                          </span>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-5 float-end">
              <nav>
                <CustomPagination
                  pageCount={pageCount}
                  setSelectedPage={setSelectedPage}
                />
              </nav>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Performance Group Modal only Image */}
      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} className="img-fluid" alt="" />
        </ModalBody>
      </Modal>

      {showRecommendModal && (
        <RecommendationFeedback
          showRecommendModal={showRecommendModal}
          setShowRecommendModal={setShowRecommendModal}
          parentDefaultCourse={defaultCourse}
          parentMealPeriod={mealPeriod}
        />
      )}

      {showVisualModal && (
        <VisualModal activeComparisonData={activeComparisonData}
          setShowVisualModal={setShowVisualModal}
          showVisualModal={showVisualModal}
          leftFilter={globalDate}
          leftCustomDate={globalCustomDate}
          rightFilter={rightFilter}
          mealPeriod={mealPeriod}
          defaultCourse={defaultCourse}
          comparisonDat={comparisonDate} />
      )}

      {showItemModal && (
        <SellingItemPopup itemData={itemData}
          showItemModal={showItemModal}
          setshowItemModal={setshowItemModal}
          togItemName={togItemName}
          leftFilter={globalDate}
          leftCustomDate={globalCustomDate}
          rightFilter={rightFilter}
          mealPeriod={mealPeriod}
          defaultCourse={defaultCourse}
          selectedPage={selectedPage}
          recalculate={recalculate} 
          currency={currency}/>
      )}

    </>
  );
};

export default BestSellingProducts;
