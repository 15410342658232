import React from 'react';
import { Col, Row, Card, CardHeader, CardBody, Button, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Input, Label, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Mock API response
const apiResponse = {
    columns: [
        { name: 'Food Capital', selector: 'name', sortable: true, width: '18%' },
        { name: 'Revenue', selector: 'revenue', sortable: true, width: '8%' },
        { name: '%vBUD', selector: 'rev_bud', sortable: true },
        { name: '%vLY', selector: 'rev_ly', sortable: true },
        { name: 'Covers', selector: 'covers', sortable: true },
        { name: '%vBUD', selector: 'covers_bud', sortable: true },
        { name: '%vLY', selector: 'covers_ly', sortable: true },
        { name: 'AVG CHK', selector: 'avgchk', sortable: true },
        { name: '%vBUD', selector: 'avgchk_bud', sortable: true },
        { name: '%vLY', selector: 'avgchk_ly', sortable: true },
        { name: '2024 Seat OCC%', selector: 'occ', sortable: true },
        { name: '2023 Seat OCC%', selector: 'occ_bud', sortable: true },
        { name: '%vLY', selector: 'occ_ly', sortable: true },
    ],
    data: [
        {
            name: 'Year to Date (28th October)',
            revenue: '$5,402,293',
            rev_bud: '+5%',
            rev_ly: '+26%',
            covers: '215260',
            covers_bud: '+18%',
            covers_ly: '+26%',
            avgchk: '$25',
            avgchk_bud: '-$3',
            avgchk_ly: '-$0',
            occ: '77%',
            occ_bud: '61%',
            occ_ly: '+16%'
        },
        {
            name: 'Month to Date (29th October)',
            revenue: '$5,452,120',
            rev_bud: '+6%',
            rev_ly: '+28%',
            covers: '220180',
            covers_bud: '+20%',
            covers_ly: '+28%',
            avgchk: '$26',
            avgchk_bud: '-$2',
            avgchk_ly: '+$1',
            occ: '78%',
            occ_bud: '62%',
            occ_ly: '+17%',
        },
        {
            name: 'Trending to Close (31st October)',
            revenue: '$5,500,000',
            rev_bud: '+7%',
            rev_ly: '+30%',
            covers: '225000',
            covers_bud: '+22%',
            covers_ly: '+30%',
            avgchk: '$27',
            avgchk_bud: '-$1',
            avgchk_ly: '+$2',
            occ: '79%',
            occ_bud: '63%',
            occ_ly: '+18%',
        },
        {
            name: 'Below or Above Past 4 Months AVG',
            revenue: '$5,600,000',
            rev_bud: '+8%',
            rev_ly: '+32%',
            covers: '230000',
            covers_bud: '+25%',
            covers_ly: '+32%',
            avgchk: '$28',
            avgchk_bud: '+$1',
            avgchk_ly: '+$3',
            occ: '80%',
            occ_bud: '64%',
            occ_ly: '+19%',
        }
    ],
};

// Group headers
const groupedHeaders = [
    { name: 'REVENUE', columns: ['revenue', 'rev_bud', 'rev_ly'] },
    { name: 'COVERS', columns: ['covers', 'covers_bud', 'covers_ly'] },
    { name: 'AVG CHK', columns: ['avgchk', 'avgchk_bud', 'avgchk_ly'] },
    { name: 'OCCUPANCY', columns: ['occ', 'occ_bud', 'occ_ly'] },
];

// Custom header row component
const CustomHeaderRow = ({ columns, groupedHeaders }) => {
    // Define color gradients for groups
    const groupColors = [
        ['#ff4d4d', '#ff9999', '#ffe5e5'], // REVENUE shades (dark to light)
        ['#4db8ff', '#99d9ff', '#e5f4ff'], // COVERS shades (dark to light)
        ['#4dff4d', '#99ff99', '#e5ffe5'], // AVG CHK shades (dark to light)
        ['#ff944d', '#ffc199', '#fff2e5'], // OCCUPANCY shades (dark to light)
    ];    

    const groupedRow = (
        <tr>
            <th rowSpan="2" style={{ backgroundColor: '#f9f9f9' }}></th>
            {groupedHeaders.map((group, index) => (
                <th
                    key={index}
                    colSpan={group.columns.length}
                    style={{
                        textAlign: 'center',
                        backgroundColor: groupColors[index][1], // Middle shade for group header
                        color: 'black',
                    }}
                >
                    {group.name}
                </th>
            ))}
        </tr>
    );

    const individualHeaders = (
        <tr>
            {columns.map((col, index) => {
                // Determine which group the column belongs to
                const groupIndex = groupedHeaders.findIndex((group) =>
                    group.columns.includes(col.selector)
                );

                // Get the index of the column within its group
                const columnIndex = groupedHeaders[groupIndex].columns.indexOf(
                    col.selector
                );

                return (
                    <th
                        key={index}
                        style={{
                            backgroundColor: groupColors[groupIndex][columnIndex], // Apply shade based on column index in group
                            color: 'black',
                        }}
                    >
                        {col.name}
                    </th>
                );
            })}
        </tr>
    );

    return (
        <thead>
            {groupedRow}
            {individualHeaders}
        </thead>
    );
};

const FoodCapital = () => {
    return (

        <Card>
            <CardHeader className="align-items-center">
                <h4 className="card-title mb-0">Food Capital</h4>            
            </CardHeader>
            <div className="table-responsive">
                <table className="table table-bordered" style={{ backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}>
                    <CustomHeaderRow columns={apiResponse.columns.slice(1)} groupedHeaders={groupedHeaders} />
                    <tbody>
                        {apiResponse.data.map((row, index) => (
                            <tr key={index}>
                                {apiResponse.columns.map((col, idx) => (
                                    <td key={idx}>{row[col.selector]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>            
    );
};

export default FoodCapital;
