/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import ForeCastPreviewCardHeader from "../../Components/Common/ForeCastPreviewCardHeader";
import { ForeCastCover } from "./FlashCharts";

const ForeCastCovers = () => {
    const [forecastAjax, setForecastAjax] = useState(false);
    const [forecastData, setForecastData] = useState({
        covers: [],
        average_check: [],
        revPASH: []
    });

    let date = new Date();
    date.setDate(date.getDate() - 1);

    let date_second = new Date();
    date_second.setDate(date_second.getDate() - 1);

    useEffect(()=>{
        setForecastData({
            "covers": [
                139,
                183,
                298,
                397,
                260,
                161,
                60
            ],
            "average_check": [
                99,
                109,
                114,
                119,
                98,
                107,
                48
            ],
            "revPASH": [
                24,
                35,
                61,
                84,
                46,
                29,
                10
            ]
        });
    },[])
    return (
        <React.Fragment>
            <Card>
                <ForeCastPreviewCardHeader
                title="Forecast - Covers, Average Check  and  Revenue"
                toggleText="Show Table"
                />
                <CardBody>
                {forecastAjax && <Loader />}
                <div className={forecastAjax ? "opacity-0" : ""}>
                    <div className="live-preview">
                    <ForeCastCover
                        forecastData={forecastData}
                        dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                    />
                    </div>
                    <div className="d-none code-view">
                    <div className="table-responsive table-card">
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead className="text-muted table-light">
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col">Covers</th>
                                <th scope="col">Avg Checks</th>
                                <th scope="col">RevPash</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(forecastData.covers || []).map(
                            (item, key) => {
                                date.setDate(date.getDate() + 1);
                                return (
                                <tr key={key}>
                                    <td>
                                    {date.toLocaleString("en-us", {
                                        day: "2-digit",
                                    }) +
                                        " " +
                                        date.toLocaleString("en-us", {
                                        month: "short",
                                        })}
                                    </td>
                                    <td>{item}</td>
                                    <td>
                                    {forecastData.average_check[key] ?? ""}
                                    </td>
                                    <td>
                                    {forecastData.revPASH[key] ?? ""}
                                    </td>
                                </tr>
                                );
                            }
                            )}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ForeCastCovers;
