/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody, Button,Modal,ModalHeader,ModalBody,FormFeedback,Form,Input,Label, ModalFooter } from "reactstrap";
import UiContent from "../../Components/Common/UiContent";
import Loader from "../../Components/Common/Loader"
import { currencyFormatWithAlert,convertDateFormate,getLocalStorageData } from "../../helpers/api_helper"
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector } from "react-redux";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServerUpselling = () => {
    const userOutletDetails = getLocalStorageData('userOutletDetails');
    const currency=userOutletDetails?.currency??"$";
    const [serverUpsellingAjax, setServerUpsellingAjax] = useState(false);
    const [filterEmployees, setFilterEmployees] = useState([]);
    const [filterEmployees_add, setFilterEmployees_add] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [filterItems_add, setFilterItems_add] = useState([]);
    const [courseItems, setCourseItems] = useState([]);
    const [courseItemAjax, setCourseItemAjax] = useState(false);
    const [server, setServer] = useState(0);    
    const [item, setItem] = useState(0);
    const [addUpsellingModal, setAddUpsellingModal] = useState(false);
    const [filtercourses, setFilterCourses] = useState([]);
    const [addItemAjax, setAddItemAjax] = useState(false);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            // width: 260,
        }),
    };
    const [modalConfirm, setModalConfirm] = useState(false);
    const [targetData, setTargetData] = useState({});

    const [selectedServers, setselectedServers] = useState("0");
    const handleServer = (singleOption) => {        
        setselectedServers(singleOption);
        setServer(singleOption.value);
    };
    
    const [selectedItems, setselectedItems] = useState("0");
    const handleItem = (singleOption) => {        
        setselectedItems(singleOption);
        setItem(singleOption.value);
    };

    const { serverList } = useSelector((state) => ({
        serverList: state.Dashboard.filterData.serverList,
    }));
    const { courses } = useSelector((state) => ({
        courses: state.Dashboard.filterData.courses,
    }));    

    const hideConfirmModal=() => {
        setModalConfirm(false);
    }
    const handleToastMessage = (type,message) => {
        switch(type){
          case "error":
            toast.error(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" });
            break;
          case "success":
            toast.success(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
            break;
        }
        
      }
    const [serverData,setServerData]=useState([])
    const loadUpSellingItems=()=>{
        // console.log("loadUpSellingItems");
        setServerUpsellingAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'upselling_list',
            item_id:item,
            server_id:server
        }).then((response) => {
            setServerUpsellingAjax(false);
            // console.log(response);
            if (response.status === "ok") {
                setServerData(response.data);
            }
        });
    }
    useEffect(()=>{
        loadUpSellingItems();
        
        if(selectedServers===false){            
            if(server){
                setselectedServers(server);        
            }else{                
                setselectedServers(filterEmployees[0]);                
            }
        }

        if(selectedItems===false){            
            if(item){
                setselectedItems(item);        
            }else{                
                setselectedItems(filterItems[0]);                
            }
        }

    },[server,item,filterEmployees,filterItems])
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          server_id: { value: 0, label: "Select Server" },
          from:"",
          to: "",
          course_id:"",
          item_id: { value: 0, label: "Select Item" },
          target: "",
          incentive_type:"fix",
          incentive_value:"",
          target_commission:""
        },
        validationSchema: Yup.object({
        //   server_id: Yup.string().required("Please select server"),
          server_id: Yup.object().shape({
            value: Yup.number().notOneOf([0], "Please select server"),
            label: Yup.string().required("Please select server")
          }).nullable().required("Please select server"),
          from: Yup.string().required("Please enter from date"),
          to: Yup.string().required("Please enter to date"),
          course_id: Yup.string().required("Please select course"),
            //   item_id: Yup.string().required("Please select item"),
            item_id: Yup.object().shape({
            value: Yup.number().notOneOf([0], "Please select item"),
            label: Yup.string().required("Please select item")
          }).nullable().required("Please select server"),
          target: Yup.string().required("Please enter target"),
          incentive_type: Yup.string().required("Please select incentive type"),
          incentive_value: Yup.string().required("Please enter incentive"),
          target_commission:Yup.string()
          .when('incentive_type', {
            is: (incentive_type) => incentive_type=='percentage',
            then: Yup.string()
              .required('Please enter target commission')            
          })
        }),
        onSubmit: (values) => {    
            let res = values;
            res.server_id = res.server_id.value;
            res.item_id = res.item_id.value;
            setAddItemAjax(true);        
            postDataAPI({
                serviceName: "item_data",
                op: 'upselling_add',
                ...res
            }).then((response) => {
                // console.log(response);
                setAddItemAjax(false);
                if (response.status === "ok") {
                    handleToastMessage('success',response.message)                
                    hideAddUpsellingModal();
                    loadUpSellingItems();
                }else{
                    handleToastMessage('error',response.message)
                }
            });
        },
    });
    const showAddUpsellingModal=() => {
        setAddUpsellingModal(true);
    }
    const hideAddUpsellingModal=() => {
        validation.resetForm();
        setAddUpsellingModal(false);
    }
    const convertValue=(value)=>{
        if(value==''){
            return 0;
        }else{
            return parseFloat(value);
        }
    }
    useEffect(()=>{        
        if(validation.values.incentive_type=='fix'){
            let target_commission=(convertValue(validation.values.incentive_value)*convertValue(validation.values.target)).toFixed(2);
            validation.setFieldValue('target_commission',target_commission.toString());
        }   
    },[validation.values.incentive_type,validation.values.incentive_value,validation.values.target])
    const handleConfirmDeleteTarget=()=>{
        setAddItemAjax(true);        
        postDataAPI({
            serviceName: "item_data",
            op: 'upselling_delete',
            target_id:targetData.target_id
        }).then((response) => {
            setAddItemAjax(false);
            if (response.status === "ok") {
                handleToastMessage('success',response.message)                
                const serverFilterData=serverData.map((server)=>{
                    if(server.server_id==targetData.server_id){
                        server.items=server.items.filter((item)=>item.target_id!=targetData.target_id);
                    }
                    return server;          
                });
                setServerData(serverFilterData.filter((server)=>server.items.length>0));
                setModalConfirm(false);
            }else{
                handleToastMessage('error',response.message)
            }
        });        
    }
    const handleDeleteTarget=(server_id,target_id)=>{
        setTargetData({server_id:server_id,target_id:target_id})
        setModalConfirm(true);
    }
    useEffect(()=>{
        if(validation.values.course_id){
            getItems(validation.values.course_id);
        }else{
            setCourseItems([]);
            setFilterItems_add([{
                "value": "0",
                "label": "Select Item"                
            }]);
        }
    },[validation.values.course_id])
    useEffect(()=>{
        setFilterCourses(courses);
    },[courses])
    useEffect(() => {
        if (serverList) {
            const updatedServerList = [{
                "label": "All Servers",
                "value": "0"
            }, ...serverList];
            setFilterEmployees(updatedServerList);

            const updatedServerList_add = [{
                "label": "Select Server",
                "value": "0"
            }, ...serverList];
            setFilterEmployees_add(updatedServerList_add);        

            // Ensure that selectedServers is set to "0" on page load
            setselectedServers("0");
        }        
                        
    }, [serverList]);

    const getItems =(course_id) => {
        setCourseItemAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'fetch_item_dropdown',
            course_id:course_id
        }).then((response) => {
            // console.log(response);
            setCourseItemAjax(false);
            if (response.status === "ok" && response.data) {
                
                setCourseItems(response.data);
                setFilterItems_add([]);

                setTimeout(function() {
                    // Assuming response.data is fetched dynamically and you want to include the same two rows
                    const finalUpdatedResponse = [
                        {
                            "value": "0",
                            "label": "Select Item"
                        },
                        {
                            "value": "",
                            "label": "All Items"
                        },
                        ...response.data
                    ];
            
                    // Set the final updated array to filterItems_add
                    setFilterItems_add(finalUpdatedResponse);
                }, 1000);
                
            }
        });
    }    
    useEffect(()=>{
        setServerUpsellingAjax(true);
        postDataAPI({
            serviceName: "item_data",
            op: 'fetch_item_dropdown',
            course_id:0
        }).then((response) => {
            setServerUpsellingAjax(false);
            if (response.status === "ok" && response.data) {
                setFilterItems(response.data);

                if (response.data) {
                    const updatedcourses = [{            
                        "value": "0",
                        "label": "All Items"
                    }, ...response.data];
                    setFilterItems(updatedcourses);
        
                    const updatedcourses_add = [{
                        "value": "0",
                        "label": "Select Item"                
                    }, ...filterItems_add];
                    setFilterItems_add(updatedcourses_add);            
        
                    // Ensure that selectedCourses is set to "0" on page load
                    setselectedItems("0");
                }
            }
        });
    },[])
    
    // console.log('FilterItems',filterItems);

    const handleServerChange = (selectedOption) => {
        validation.setFieldValue('server_id', selectedOption);
    };

    const handleItemChange = (selectedOption) => {
        validation.setFieldValue('item_id', selectedOption);
    };

    const hasError_server = validation.touched.server_id && validation.errors.server_id;    
    const customStyles_error_server = (hasError) => ({
        control: (base) => ({
            ...base,
            borderColor: hasError ? '#f06548' : base.borderColor,
            '&:hover': {
                borderColor: hasError ? '#f06548' : base['&:hover'].borderColor
            }
        })
    });
    const hasError_item = validation.touched.server_id && validation.errors.item_id;
    const customStyles_error_item = (hasError_item) => ({
        control: (base) => ({
            ...base,
            borderColor: hasError_item ? '#f06548' : base.borderColor,
            '&:hover': {
                borderColor: hasError_item ? '#f06548' : base['&:hover'].borderColor
            }
        })
    });
    
    return <React.Fragment>
        <UiContent />
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Upselling" pageTitle="Dashboard" />
                <Row className="mb-4">
                    <Col sm={12} lg={4}>
                        {/* <select
                            className="form-select"
                            value={server}
                            id="server"
                            onChange={(event) => setServer(event.target.value)}
                        >
                            <option value="0">All Server</option>
                            {filterEmployees.length > 0 &&
                            filterEmployees.map((server) => {
                                return (
                                <option key={server.value} value={server.value}>
                                    {server.label}
                                </option>
                                );
                            })}
                        </select> */}
                        <Select
                            value={filterEmployees.find(option => option.value === selectedServers)}
                            isMulti={false}                            
                            onChange={handleServer}
                            options={filterEmployees}
                            styles={customStyles}
                        />
                    </Col>
                    <Col sm={12} lg={4}>
                        {/* <select
                            className="form-select"
                            value={item}
                            id="item"
                            onChange={(event) => setItem(event.target.value)}
                        >
                            <option value="0">All Item</option>
                            {filterItems.length > 0 &&
                            filterItems.map((item) => {
                                return (
                                <option key={item.item_id} value={item.item_id}>
                                    {item.item_name}
                                </option>
                                );
                            })}
                        </select> */}
                        <Select
                            value={filterItems.find(option => option.value === selectedItems)}
                            isMulti={false}                            
                            onChange={handleItem}
                            options={filterItems}
                            styles={customStyles}
                        />
                    </Col>
                    <Col sm={12} lg={4} className="text-end">
                        <Button color="primary" className="btn-label" onClick={showAddUpsellingModal}> <i className=" ri-add-fill label-icon align-middle fs-16 me-2"></i> Add </Button>
                    </Col>
                </Row>
                {
                    serverData.length === 0 ? (
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">No data !</h4>
                            </CardHeader>  
                        </Card>
                        ) : (
                            serverData.map((server)=>{                        
                                return <Card key={server.server_id}>
                                    <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{server.server_name}</h4>
                                    </CardHeader>                    
                                    <CardBody>
                                        {
                                            serverUpsellingAjax && <Loader />
                                        }
                                        <div className={
                                            serverUpsellingAjax
                                                ? "opacity-0 table-responsive table-card"
                                                : "table-responsive table-card"
                                        }>
                                            <table className="table align-middle table-nowrap table-bordered mb-0">
                                                <thead className="table-light">
                                                    <tr style={{ textAlign: 'center',verticalAlign: "middle" }}>
                                                        <th scope="col" rowSpan={2}>Item</th>
                                                        <th scope="col" rowSpan={2}>FROM</th>
                                                        <th scope="col" rowSpan={2}>TO</th>
                                                        <th scope="col" rowSpan={2}>Course</th>
                                                        <th scope="col" rowSpan={2}>Incentive</th>
                                                        <th scope="col" colSpan={2}>Target</th>
                                                        <th scope="col" colSpan={3}>Actual</th>
                                                        <th scope="col" rowSpan={2}>Action</th>
                                                    </tr>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th scope="col">Qty </th>
                                                        <th scope="col">Commission</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Revenue</th>
                                                        <th scope="col">Commission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        server.items.map((item)=>{
                                                            return <tr key={item.target_id}>
                                                                <td className="text-start">{item.item_name}</td>
                                                                <td className="text-center">{item.from}</td>
                                                                <td className="text-center">{item.to}</td>
                                                                <td className="text-center">{item.course_name}</td>
                                                                <td className="text-center">{item.incentive}</td>
                                                                <td className="text-center">{item.target}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.target_commission,currency)}</td>
                                                                <td className="text-center">{item.actual_sold}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.item_revenue,currency)}</td>
                                                                <td className="text-center">{currencyFormatWithAlert(item.actual_commission,currency)}</td>
                                                                <td className="text-center">
                                                                <Button onClick={(event)=>{handleDeleteTarget(server.server_id,item.target_id)}} color="danger" className="btn-icon waves-effect waves-light"> <i className="ri-delete-bin-5-line" /> </Button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card> 
                            })
                        )
                }
            </Container>
        </div>
        <Modal
            size="xl"
            isOpen={addUpsellingModal}
            toggle={hideAddUpsellingModal}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <Form
            className="form-horizontal"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
                <ModalHeader className={"modal-header title-full-width"}>
                    <div style={{"display":"flex","justify-content":"space-around"}}>
                        Add Upselling
                        <Button type="button" color={"link"} className="btn-close"
                            onClick={hideAddUpsellingModal} aria-label="Close"></Button>
                    </div>
                </ModalHeader>
                <ModalBody className="p-5">
                    <Row className="mb-4">
                        <Col sm={12} lg={4}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Server
                                    <span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                    name="server_id"
                                    className="form-control"
                                    type="select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.server_id || ""}
                                    invalid={
                                    validation.touched.server_id &&
                                    validation.errors.server_id
                                        ? true
                                        : false
                                    }
                                >
                                        <option value="">Select server</option>
                                        {filterEmployees.length > 0 &&
                                        filterEmployees.map((server) => {
                                            return (
                                            <option key={server.value} value={server.value}>
                                                {server.label}
                                            </option>
                                            );
                                        })}
                                </Input>
                                {validation.touched.server_id &&
                                validation.errors.server_id ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.server_id}
                                    </FormFeedback>
                                ) : null} */}

                                <Select
                                        value={validation.values.server_id}
                                        onChange={handleServerChange}
                                        options={filterEmployees_add}
                                        styles={customStyles_error_server(hasError_server)}
                                        onBlur={() => validation.setFieldTouched('server_id', true)}
                                    />
                                    {validation.touched.server_id && validation.errors.server_id ? (
                                        <div className="error_add">{validation.errors.server_id.label || validation.errors.server_id.value}</div>
                                    ) : null}

                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Course
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="course_id"
                                    className="form-control"
                                    type="select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.course_id || ""}
                                    invalid={
                                    validation.touched.course_id &&
                                    validation.errors.course_id
                                        ? true
                                        : false
                                    }
                                >
                                    <option value="">Select course</option>
                                    {filtercourses.length > 0 &&
                                    filtercourses.map((course) => {
                                        return (
                                        <option key={course.value} value={course.value}>
                                            {course.label}
                                        </option>
                                        );
                                    })}
                                </Input>
                                {validation.touched.course_id &&
                                validation.errors.course_id ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.course_id}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Item
                                    <span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                    name="item_id"
                                    className="form-control"
                                    type="select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.item_id || ""}
                                    invalid={
                                    validation.touched.item_id &&
                                    validation.errors.item_id
                                        ? true
                                        : false
                                    }
                                >
                                    {courseItemAjax?<option value="">Loading items</option>:<>
                                    <option value="">Select item</option>
                                    {courseItems.length > 0 && <option value="0">All items</option> }
                                    {courseItems.length > 0 &&
                                    courseItems.map((item) => {
                                        return (
                                        <option key={item.item_id} value={item.item_id}>
                                            {item.item_name}
                                        </option>
                                        );
                                    })}
                                    </>}
                                </Input>
                                {validation.touched.item_id &&
                                validation.errors.item_id ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.item_id}
                                    </FormFeedback>
                                ) : null} */}

                                <Select
                                    value={validation.values.item_id}
                                    onChange={handleItemChange}
                                    options={filterItems_add}
                                    styles={customStyles_error_server(hasError_server)}
                                    onBlur={() => validation.setFieldTouched('item_id', true)}
                                />
                                {validation.touched.item_id && validation.errors.item_id ? (
                                    <div className="error_add">{validation.errors.item_id.label || validation.errors.item_id.value}</div>
                                ) : null}

                            </div>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    From
                                    <span className="text-danger">*</span>
                                </Label>

                                <Flatpickr
                                    name="from"
                                    className={`form-control flatpickr-input ${(validation.touched.from &&
                                        validation.errors.from
                                            ? "is-invalid"
                                            : "")}`}
                                    options={{
                                        dateFormat: "d M, Y",
                                        mode:"single"
                                    }}
                                    onChange={([date])=>{
                                        validation.setFieldValue('from',convertDateFormate(date));
                                    }}
                                />
                                
                                {validation.touched.from &&
                                validation.errors.from ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.from}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    To
                                    <span className="text-danger">*</span>
                                </Label>
                                <Flatpickr
                                    name="to"
                                    className={`form-control flatpickr-input ${(validation.touched.to &&
                                        validation.errors.to
                                            ? "is-invalid"
                                            : "")}`}
                                    options={{
                                        dateFormat: "d M, Y"
                                    }}
                                    onChange={([date])=>{
                                        validation.setFieldValue('to',convertDateFormate(date));
                                    }}
                                />
                                {validation.touched.to &&
                                validation.errors.to ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.to}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Incentive Type
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="incentive_type"
                                    className="form-control"
                                    type="select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.incentive_type || ""}
                                    invalid={
                                    validation.touched.incentive_type &&
                                    validation.errors.incentive_type
                                        ? true
                                        : false
                                    }
                                >
                                    <option value="fix">Incentive per item</option>
                                    <option value="percentage">% of revenue</option>
                                </Input>
                                {validation.touched.incentive_type &&
                                validation.errors.incentive_type ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.incentive_type}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Incentive
                                    <span className="text-danger">*</span>
                                </Label>
                                <div  className={
                                        validation.touched.incentive_value &&
                                        validation.errors.incentive_value
                                            ? "input-group is-invalid"
                                            : "input-group"
                                        }>
                                    {validation.values.incentive_type=='fix' && <span className="input-group-text">{currency}</span>}
                                    <Input
                                        name="incentive_value"
                                        className="form-control"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.incentive_value || ""}         
                                        invalid={
                                            validation.touched.incentive_value &&
                                            validation.errors.incentive_value
                                                ? true
                                                : false
                                        }                               
                                    />
                                    {validation.values.incentive_type=='percentage' && <span className="input-group-text">%</span>}
                                </div>                                
                                {validation.touched.incentive_value &&
                                validation.errors.incentive_value ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.incentive_value}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>            
                        
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Target Qty
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="target"
                                    className="form-control"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.target || ""}
                                    invalid={
                                    validation.touched.target &&
                                    validation.errors.target
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.target &&
                                validation.errors.target ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.target}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>            
                        <Col sm={12} lg={6}>
                            <div className="form-group mb-3">
                                <Label className="form-label">
                                    Target Commission
                                    <span className="text-danger">*</span>
                                </Label>
                                <div  className={
                                        validation.touched.target_commission &&
                                        validation.errors.target_commission
                                            ? "input-group is-invalid"
                                            : "input-group"
                                        }>
                                    <span className="input-group-text">{currency}</span>
                                    <Input
                                        name="target_commission"
                                        className="form-control"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.target_commission || ""}
                                        invalid={
                                        validation.touched.target_commission &&
                                        validation.errors.target_commission
                                            ? true
                                            : false
                                        }
                                        readOnly={validation.values.incentive_type=='fix'}
                                    />
                                </div>
                                {validation.touched.target_commission &&
                                validation.errors.target_commission ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.target_commission}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={hideAddUpsellingModal} color="light">Cancel</Button>
                    <Button type="submit" color="primary" disabled={addItemAjax}>Add</Button>
                    {addItemAjax && <Loader />}
                </ModalFooter>
            </Form>
        </Modal>
        <Modal
                isOpen={modalConfirm}
                toggle={hideConfirmModal}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center">
                    <h1 style={{fontSize:"90px",lineHeight:"0.5"}}><i className="ri-information-line text-warning"></i></h1>
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure you want to delete?</h4>
                        <h5 className="text-danger">Please be aware, this action CAN NOT be undone.</h5>
                        <div className="hstack gap-2 justify-content-center mt-4">
                            <Button color="light" onClick={hideConfirmModal}>Close</Button>
                            <Button onClick={handleConfirmDeleteTarget} className="btn btn-danger">Delete</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        <ToastContainer />
    </React.Fragment>
}

export default ServerUpselling;