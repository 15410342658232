import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, Input } from 'reactstrap';
import { Link,useHistory } from 'react-router-dom';
import classnames from 'classnames';

//SimpleBar
import SimpleBar from "simplebar-react";
import { getLoggedinUser } from "../../helpers/api_helper";
import { userOutletUpdate } from "../../store/actions";

const OutletDropdown = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    //Dropdown Toggle
    const [isOutletDropdown, setIsOutletDropdown] = useState(false);
    const [searchText, setSearchText] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const toggleOutletDropdown = () => {
        setIsOutletDropdown(!isOutletDropdown);
    };
    
    const { user, currentOutlet,userOutlets, userGroups, userUnits } = useSelector(state => ({
        user: state.Profile.user,
        currentOutlet: state.UserOutletGroup.currentOutlet,
        userOutlets: state.UserOutletGroup.userOutlets,
        userGroups: state.UserOutletGroup.userGroups,
        userUnits: state.UserOutletGroup.userUnits
    }));

    useEffect(()=>{
        setOutletList(Object.values(userOutlets));
    },[userOutlets]);

    useEffect(()=>{
        setGroupList(Object.values(userGroups));
    },[userGroups]);

    useEffect(()=>{
        setUnitList(Object.values(userUnits));
    },[userUnits]);
    
    useEffect(()=>{
        if(searchText){
            setOutletList(Object.values(userOutlets).filter((outlet)=>outlet.outlet_name.toLowerCase().includes(searchText.toLowerCase())));
            setGroupList(Object.values(userGroups).filter((group)=>group.group_name.toLowerCase().includes(searchText.toLowerCase())));
            setUnitList(Object.values(userUnits).filter((unit)=>unit.unit_name.toLowerCase().includes(searchText.toLowerCase())));
        }else{
            setOutletList(Object.values(userOutlets));
            setGroupList(Object.values(userGroups));
            setUnitList(Object.values(userUnits));
        }
    },[searchText])

    const [userID, setUserID] = useState(false);
    const [outletGroupInfo, setOutletGroupInfo] = useState('No Outlet');

    useEffect(() => {
        const obj = getLoggedinUser();
        if (obj) {
            setUserID(user.user_id || obj.user_id || false);
        }
        if(currentOutlet){
            setOutletGroupInfo(currentOutlet.type+' - '+currentOutlet.name);
        }
    }, [currentOutlet]);
    //Tab 
    
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const selectOutlet = (outlet) => {
        dispatch(userOutletUpdate({typeID:outlet.outlet_id,type:'outlet',userID:userID}, history));
        setIsOutletDropdown(false);
    };

    const selectGroup = (group) => {
        dispatch(userOutletUpdate({typeID:group.group_id,type:'group',userID:userID}, history));
        setIsOutletDropdown(false);
    };

    const selectUnit = (unit) => {
        dispatch(userOutletUpdate({typeID:unit.unit_id,type:'unit',userID:userID}, history));
        setIsOutletDropdown(false);
    };
    if(!outletGroupInfo)
        return;

    return (
        <React.Fragment>
            <Dropdown isOpen={isOutletDropdown} toggle={toggleOutletDropdown} className="ms-3 topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-success">
                    { outletGroupInfo }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" end={true}>
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="px-3 pt-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> { outletGroupInfo } </h6>
                                </Col>
                            </Row>
                        </div>
                        <div className="p-3 app-search">
                            <div className='position-relative'>
                                <Input
                                    name="search"
                                    type="text"
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={(event)=>{setSearchText(event.target.value)}}
                                />
                                <span className="mdi mdi-magnify search-widget-icon"></span>
                            </div>
                        </div>
                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        Outlet
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Group
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                    >
                                        Unit
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "200px", overflowY: 'auto' }} className="pe-2">
                                {
                                    outletList.length>0 && outletList.map(function(outlet){
                                        return <div key={outlet.outlet_id} className="text-reset notification-item d-block dropdown-item position-relative">
                                                <div className="d-flex">
                                                    <div className="flex-1">
                                                        <Link to="#" className="stretched-link" onClick={() => selectOutlet(outlet)}>
                                                            <h6 className="mt-0 mb-2 lh-base">{outlet.outlet_name}</h6>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                    })
                                }
                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "200px" }} className="pe-2">
                                {
                                    groupList.length > 0 && groupList.map(function(group){
                                        return <div key={group.group_id} className="text-reset notification-item d-block dropdown-item position-relative">
                                            <div className="d-flex">
                                                <div className="flex-1">
                                                    <Link to="#" className="stretched-link" onClick={() => selectGroup(group)}>
                                                        <h6 className="mt-0 mb-2 lh-base">{group.group_name}</h6>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="3" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "200px" }} className="pe-2">
                                {
                                    unitList.length > 0 && unitList.map(function(unit){
                                        return <div key={unit.unit_id} className="text-reset notification-item d-block dropdown-item position-relative">
                                            <div className="d-flex">
                                                <div className="flex-1">
                                                    <Link to="#" className="stretched-link" onClick={() => selectUnit(unit)}>
                                                        <h6 className="mt-0 mb-2 lh-base">{unit.unit_name}</h6>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default OutletDropdown;